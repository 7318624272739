<template>
  <base-section
    id="map"
    space="0"
  >
    <base-section-heading title="La empresa de conservas de pescado se encuentra situada en Maliaño, Cantabria">
    </base-section-heading>
    <iframe
      allowfullscreen
      aria-hidden="false"
      frameborder="0"
      height="500"
      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11590.630037614894!2d-3.843161!3d43.425982!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xa74d19ec37798cdc!2sCantdelimar%20Sc!5e0!3m2!1ses!2ses!4v1595259836139!5m2!1ses!2ses"
      style="border:0; display: block;"
      tabindex="0"
      width="100%"
    />
  </base-section>
</template>

<script>
  export default {
    name: 'SectionMap',

    provide: {
      heading: { align: 'center' },
    },
  }
</script>
